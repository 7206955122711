<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-container class="py-0 fill-height">
        <v-responsive max-width="260">
          <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
            label="Search Icons"
            v-model="searchText"
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <icons-list :searchText="searchText"></icons-list>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import iconsList from "./components/icons-list.vue";

export default {
  components: { iconsList },
  data: () => ({
    searchText: "",
    links: ["Dashboard", "Messages", "Profile", "Updates"],
  }),
};
</script>