<template>
  <div class="mt-8">
    <v-sheet tyle="height: 70vh">
      <v-row>
        <v-col v-for="(icon, index) in allIcons" :key="index">
          <v-tooltip bottom style="margin-top: 10px">
            <template v-slot:activator="{ on, attrs }">
              <span class="m-4" style="padding: 15px" v-bind="attrs" v-on="on">
                <v-icon
                  @click="
                    show = true;
                    selectedIcon = icon;
                  "
                  class="p-4"
                  x-large
                >
                  mdi-{{ icon.name }}
                </v-icon>
              </span>
            </template>
            <span> mdi-{{ icon.name }} </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="!allIcons || (allIcons && allIcons.length == 0)">
        <v-col>
          <h2>Fuckall Icons bruv</h2>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog v-model="show" max-width="400px">
      <v-card>
        <v-card-title>
          <h4>mdi-{{ selectedIcon.name }}</h4>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    allIcons() {
      return this.iconList;
    },
  },
  props: {
    searchText: {
      type: String,
      default: "",
    },
  },
  watch: {
    searchText() {
      clearTimeout(this.tId);
      this.tId = setTimeout(() => {
        this.getData();
      }, 200);
    }
  },
  mounted() {},
  methods: {
    getData() {
      fetch("https://api.materialicons.caffeinatedramen.dev/api/Icon?Search=" + this.searchText).then(
        (response) => {
          response.json().then((json) => {
            this.iconList = json;
          });
        }
      );
    },
  },
  data: () => ({
    tId: '',
    selectedIcon: "",
    show: false,
    iconList: [],
  }),
};
</script>